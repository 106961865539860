import request from '@/utils/request'
import CrudAppService from '@/framework/CrudAppService'
import {
  TrainingActionDto,
  CreateOrUpdateTrainingActionDto,
  GetListTrainingActionDto
} from '@/framework/models/TrainingAction'
import {Guid} from 'guid-typescript'

export default class TrainingActionApi extends CrudAppService <TrainingActionDto, TrainingActionDto, Guid,
  GetListTrainingActionDto, CreateOrUpdateTrainingActionDto, CreateOrUpdateTrainingActionDto> {
  constructor() {
    super('/keep-fit/trainingAction');
  }
}
