import {ExtensibleEntityDto} from './ExtensibleEntityDto'
import {PagedAndSortedResultRequestDto} from './PagedAndSortedResultRequestDto'
import {Guid} from 'guid-typescript';

export interface TrainingActionDto extends ExtensibleEntityDto <Guid> {
  name: string
  trainingType: string
  muscleSiteId: string
  remark: string
}

export class CreateOrUpdateTrainingActionDto {
  public name: string = ''
  public trainingType: string = ''
  public muscleSiteId: string = ''
  public remark: string = ''
  public equipmentIds: any = []
}

export class GetListTrainingActionDto extends PagedAndSortedResultRequestDto {
  public filter: string = ''
}
