















































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {PageResultDto} from '@/framework/models/ResultDto';
import {
  TrainingActionDto,
  CreateOrUpdateTrainingActionDto,
  GetListTrainingActionDto
} from '@/framework/models/TrainingAction';
import TrainingActionApi from '@/api/prescription/TrainingAction';

import TrainingTypeApi from '@/api/prescription/TrainingType'
import MuscleSiteApi from '@/api/prescription/MuscleSite'
import SportsEquipmentApi from '@/api/gym/SportsEquipment'

@Component({})
export default class TrainingActionIndex extends Vue {
  private api = new TrainingActionApi();
  private typeApi = new TrainingTypeApi();
  private siteApi = new MuscleSiteApi();
  private equipmentApi = new SportsEquipmentApi();

  private listQuery = new GetListTrainingActionDto();
  private tableData: PageResultDto<TrainingActionDto> = {
    items: new Array<TrainingActionDto>(),
    totalCount: 0
  };
  private dialog = {
    title: '',
    visible: false
  }
  private dataForm: CreateOrUpdateTrainingActionDto = {
    equipmentIds: [],
    name: '',
    trainingType: '',
    muscleSiteId: '',
    remark: ''
  }
  private rules = {
    name: [{
      required: true,
      message: '名称不能为空',
      trigger: 'blur'
    }],
    trainingType: [{
      required: true,
      message: '训练类型不能为空',
      trigger: 'blur'
    }]
  }

  private trainingTypeRadioOptions = new Array()

  private muscleSiteIdRadioOptions = new Array()

  private sportsEquipmentsOptions = new Array()

  private async activated() {
    await this.getList();
  }

  private async getList() {
    this.tableData = await this.api.getListAsync(this.listQuery);
    await this.getAllList()
    await this.getSportAllList()
  }

  private async getAllList() {
    const result = await this.typeApi.getAllList()
    this.trainingTypeRadioOptions = result.items

    const sites = await this.siteApi.getAllList()
    this.muscleSiteIdRadioOptions = sites.items
  }

  private async getSportAllList() {
    const result = await this.equipmentApi.getAllList()
    this.sportsEquipmentsOptions = result.items
  }

  private getTypeName(row: any) {
    const result = this.trainingTypeRadioOptions.find((x: any) => {
      if (x.id === row.trainingType) {
        return x;
      }
    })
    if (result) {
      return result.name
    }
  }

  private getSiteName(row: any) {
    const result = this.muscleSiteIdRadioOptions.find((x: any) => {
      if (x.id === row.muscleSiteId) {
        return x;
      }
    })
    if (result) {
      return result.name
    }
  }

  private handleCreate() {
    this.resetForm();
    this.dialog.title = '新增';
    this.dialog.visible = true;
    this.$nextTick(() => {
      (this.$refs.dataForm as any).clearValidate();
    });
  }

  private async updateHandle(row: any) {
    this.resetForm();
    const result = await this.api.getAsync(row.id);
    if (result) {
      this.dialog.visible = true;
      this.dialog.title = '编辑';
      this.dataForm = Object.assign({equipmentIds: []}, result);
    }

    this.$nextTick(() => {
      (this.$refs.dataForm as any).clearValidate();
    });
  }

  private resetForm() {
    this.dataForm = {
      equipmentIds: undefined,
      name: '',
      trainingType: '',
      muscleSiteId: '',
      remark: ''
    }
  }

  private handelConfirm() {
    (this.$refs.dataForm as any).validate(async (valid: any) => {
      if (!valid) {
        return;
      }
      if (this.dialog.title === '新增') {
        await this.creating(this.dataForm);
      } else if (this.dialog.title === '编辑') {
        await this.editing(this.dataForm);
      }
    });
  }

  private async creating(row: any) {
    await this.api.CreateAsync(row);
    await this.getList();
    this.close();
    this.$notify({
      message: '添加成功',
      type: 'success',
      title: ''
    });
  }

  private async editing(row: any) {
    await this.api.UpdateAsync(row.id, row);
    await this.getList();
    this.close();
    this.$notify({
      message: '编辑成功',
      type: 'success',
      title: ''
    });
  }

  private handlerDelete(row: any) {
    this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'error'
    }).then(async () => {
      // TODO 代码
      await this.api.DeleteAsync(row.id);
      await this.getList();
      this.$notify({
        message: '删除成功',
        type: 'success',
        title: ''
      });
    }).catch(() => {
    });
  }

  private close() {
    this.dialog.visible = false;
  }
}
